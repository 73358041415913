/* START Overlay nav */
nav.overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	background-color: var(--backgroundcolor);
	overflow-x: hidden;
	transition: width 0.5s;
}
.overlay-content {
	/* position: relative; */
	width: 100%;
	text-align: center;
	margin: 0;
	padding: 0 0 15px 0;
}
nav.overlay div.closebtn {
	position: absolute;
	top: 0.1em;
	right: 0.5em;
	font-size: 8vw;
	z-index: 2;
	padding: 8px;
	text-decoration: none;
	color: var(--navtext);
	transition: 0.3s;
	cursor: pointer;
}
nav.overlay div.closebtn:hover,
nav.overlay div.closebtn:focus {
	color: var(--navchildbackgroundhover);
}
.overlay.toggled-on {
	width: 100%;
}
nav.overlay .light-toggle {
	position: absolute;
	top: 4vw;
	left: 2vw;
}
/* END Overlay nav */

/* TODO Animate dropdown and change style to match rest of the website: https://www.youtube.com/watch?v=S-VeYcOCFZw&list=WL&index=6&t=11s */
/* START dropdown nav */
#logo {
	/* font-size: 48px; */
	font-size: 7vw;
	text-align: center;
	margin: 10px 0;
	flex-grow: 1;
}
#logo a {
	text-decoration: none;
	color: inherit;
}
.menu {
	text-align: left;
	align-items: left;
}
.menu a {
	display: block;
	margin: 0;
	text-decoration: none;
	line-height: 1;
	white-space: pre-wrap;
	padding: 10px;
	border-bottom: 0px solid var(--navtext);
	outline-offset: -5px;
}

#pages .menu li {
	flex: 1;
	justify-content: space-between;
	align-self: flex-end;
	align-items: baseline;
	position: relative;
	padding: 0;
	margin: 0 auto;
	font-size: 25px;
	list-style-type: none;
}
#pages .dropdown-content {
	margin: 0;
	min-width: 100%;
	white-space: nowrap;
	padding: 0;
}
#pages .dropdown-content li,
#pages .dropdown-content .title {
	padding: 0 0 0 15px; /* indent the child menu so it is obviously with the parent */
	margin: 0;
}
.dropdown-mega .title {
	text-decoration: underline;
}
.dropdown-mega .column {
	padding: 0 0 0 15px;
}
.menu a:link,
.menu a:visited {
	color: var(--navtextcolor);
}
.menu > li:hover > a,
.menu > li > a:focus-visible {
	color: grey;
	background-color: var(--navbackgroundhover);
}
.menu a:hover,
.menu a:focus-within {
	background-color: var(--navchildbackgroundhover);
}
.dropdown-content li:hover,
.dropdown-content li:focus-within {
	background-color: var(--navchildbackgroundhover);
}

.menu-toggle:active,
.menu-toggle.toggled-on {
	color: var(--navtextactive);
}
.menu-toggle {
	background-color: var(--navbackground);
	color: var(--navtext);
	cursor: pointer;
	margin: auto 18px;
	/* padding: 0.5%; */
	border: 1px solid var(--navchildbackground);
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	transition: 0.3s;
}
.menu-toggle:hover,
.menu-toggle:focus {
	color: var(--navtexthover);
	background-color: var(--navbackgroundhover);
}
/* toggle the nav */
.menu,
.menu > ul {
	display: none;
}
.menu.toggled-on,
.menu-toggle {
	display: block;
}
/* END dropdown nav */

#skip {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1001;
	background-color: var(--backgroundcolor, white);
}
#skip {
	width: 0px;
	padding: 0px;
	overflow: hidden;
}
#skip:focus-within {
	padding: 5px;
	width: auto;
}

/* neumorphic */
.menu a:hover,
.menu a:focus-within {
	background-color: var(--navchildbackgroundhover);
}
.dropdown-content li:hover,
.dropdown-content li:focus-within {
	background-color: var(--navchildbackgroundhover);
}
.dropdown .dropdown-content li:hover,
.dropdown .dropdown-content li:focus-within,
.dropdown-mega .dropdown-content li:hover,
.dropdown-mega .dropdown-content li:focus-within {
	box-shadow: 4px 4px 4px 0px var(--neumorphic-br, #d1d9e6) inset,
		-4px -4px 4px 0px var(--neumorphic-tl, #ffffff) inset;
	transition: box-shadow 0.5s;
}

/* ======================== Tablet ========================= */
@media all and (min-width: 1000px) {
	/* START Overlay nav */
	nav.overlay {
		height: unset;
		width: unset;
		position: unset;
		top: unset;
		left: unset;
		background-color: unset;
		overflow-x: unset;
	}
	.overlay-content {
		position: unset;
		top: unset;
		width: unset;
		padding: 0;
	}
	nav.overlay .closebtn {
		display: none;
	}
	.overlay.toggled-on {
		width: inherit;
	}
	nav.overlay #logo {
		display: none;
	}
	nav.overlay .light-toggle {
		position: unset;
	}
	/* END Overlay nav */

	/* navigation */
	#logo {
		text-align: left;
		font-size: 48px;
		width: auto;
		margin-left: 10px;
		flex-grow: 1;
	}
	#pages {
		width: 60%;
	}
	.menu {
		display: flex !important;
		text-align: center;
		align-items: center;
	}
	#pages .menu > li {
		max-width: 200px;
	}
	#pages .menu li {
		font-size: 20px;
	}
	.menu > li:hover > a,
	.menu > li:focus-within > a {
		/* font-size: 21px; */
	}
	#pages .dropdown .dropdown-content li {
		padding: 0;
	}
	.menu li:hover .dropdown-content,
	.menu li a:focus-visible + .dropdown-content,
	.menu li .dropdown-content:focus-within {
		/* display: block; */
		height: auto;
	}
	.menu > li .arrow {
		transform: rotate(180deg);
		transition: transform 0.3s;
	}
	.menu > li:hover .arrow,
	.menu > li:focus-within .arrow {
		transform: rotate(0deg);
	}
	.dropdown .dropdown-content {
		height: 0px;
		overflow: hidden;
		padding: 0; /* remove indent so child lines up with parent */
		position: absolute;
		z-index: 999;
		width: 150%;
		max-width: 500px;
	}
	.dropdown-mega {
		float: left;
		overflow: hidden;
	}
	.dropdown-mega .dropdown-content {
		height: 0px;
		overflow: hidden;
		padding: 0; /* remove indent so child lines up with parent */
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 999;
		background-color: var(--navchildbackground);
	}
	.dropdown-mega .title {
		text-align: left;
	}
	.dropdown-mega .column {
		padding: 20px;
		float: left;
	}
	.dropdown-mega.projects .column {
		width: calc(100% / var(--project-menu-columns));
	}
	.dropdown-mega .column a {
		float: none;
		text-align: left;
		display: block;
	}
	.dropdown-mega .row:after {
		content: "";
		display: table;
		clear: both;
	}
	#pages .menu .dropdown .dropdown-content li {
		max-width: 500px;
		text-align: center;
	}
	.dropdown-content li,
	.dropdown-content .title {
		padding: 0;
	}
	.dropdown-content,
	.dropdown-content .title {
		background-color: var(--navchildbackground);
	}
	.menu > ul {
		display: block !important;
	}
	.menu-toggle {
		display: none;
	}
	#pages .dropdown a:after {
		content: "";
	}

	/* neumorphic */
	.dropdown ul.dropdown-content {
		box-shadow: -8px -4px 8px 0px var(--neumorphic-tl, #ffffff),
			8px 4px 12px 0px var(--neumorphic-br, #d1d9e6);
		/* transition: box-shadow 0.5s; */
	}
	.dropdown-mega ul.dropdown-content {
		box-shadow: -8px -4px 8px 0px var(--neumorphic-tl, #ffffff),
			8px 4px 12px 0px var(--neumorphic-br, #d1d9e6);
		/* transition: box-shadow 0.5s; */
	}
}
