.github-cards {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	padding: 0;
}

.github-card {
	position: relative;
	display: block;
	box-sizing: border-box;
	/* border: 1px solid gray; */
	margin: calc(20px / 2);
	padding: 20px;
	color: var(--textcolor);
	text-decoration: none;
	font-size: 13px;
	flex: 1 1 0px;
	min-width: 250px;
}
.github-card:hover {
	/* box-shadow: 1px 1px 5px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); */
	/* override link hover */
	/* border: 1px solid gray; */
	color: var(--textcolor);
	text-decoration: none;
}

.github-card .inner > h3 {
	margin-top: 0;
	color: #0969da;
	font-size: 15px;
	font-weight: bold;
}

.github-card a {
	border-bottom: 0px solid transparent;
}

/* .github-card .inner {
	position: relative;
	pointer-events: none;
	z-index: 1;
}

.github-card .inner a {
	pointer-events: all;
} */

.github-card .overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.github-card__ext-link {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 20px;
}

.github-card__meta {
	margin-right: 20px;
}
.github-card__meta i {
	font-size: 16px;
}
.github-card__language-icon {
	position: relative;
	top: 1px;
	display: inline-block;
	width: 12px;
	height: 12px;
	border: 1px solid rgba(27, 31, 36, 0.1);
	border-radius: 50%;
}
