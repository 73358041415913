/* ======================== mobile ======================== */
:root {
	/*--gray:rgb(112, 112, 112);
	--darkgray:rgb(51, 51, 51);*/
	--backgroundcolor: #ecf0f3;
	--textcolor: black;
	--texthovercolor: gray;
	--boxshadowcolor: rgba(0, 0, 0, 0.3);
	--colspancolor: gray;
	--textboxfocuscolor: lightgray;
	--navbackground: #ecf0f3;
	--navbackgroundhover: #ecf0f3;
	--navtext: rgb(0, 0, 0);
	--navtexthover: black;
	--navtextactive: rgb(0, 0, 0);
	--navchildbackground: #ecf0f3;
	--navchildbackgroundhover: transparent;
	--navchildtext: rgb(0, 0, 0);
	--navchildtexthover: rgb(255, 255, 255);
	--navchildtextactive: rgb(255, 255, 255);
	--color-markdown-code-bg: rgba(27, 31, 35, 0.1);
	--project-menu-columns: 3; /* number of categories in projects mega menu */
}
/* start for Header menu */
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*,
*:before,
*:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}
html,
body {
	height: 100%;
}
.stop-scrolling {
	height: 100vh;
	overflow: hidden;
}
/* end for Header menu */

/* colors */

/* anchors */
a {
	text-decoration: none;
	border-bottom: 2px solid var(--textcolor);
	color: var(--textcolor);
	transition: color 0.5s, border-bottom 0.5s;
}
a:hover {
	color: var(--texthovercolor);
	border-bottom: 2px solid var(--texthovercolor);
}
#logo a {
	border-bottom: 0px solid transparent;
}
a.no-underline {
	border-bottom: 0px solid transparent;
}

/* page layout blocks */
main {
	flex: 1;
	padding-top: calc(8vw + 10px * 2 + 2%);
}
header {
	margin: 0 0 2% 0;
	background-color: var(--navbackground);
	width: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}
article {
}
footer {
	text-align: center;
	flex-shrink: 0;
}
footer li {
	display: inline-block;
}
footer li + li {
	margin-left: 1%;
}
article,
aside,
footer {
	margin: 0% 4% 2% 4%;
	padding: 2%;
}
footer a {
	border-bottom: 0px solid transparent;
}
footer a:hover {
	border-bottom: 0px solid transparent;
}
article div {
	margin: 0 0 0 0;
	padding: 2%;
}
article div h2,
article h2 {
	margin: 0 0 2% 0;
}

/* form */
form {
	text-align: left;
	font-size: 200%;
	font-size: 3vmin;
}
.form-body {
	display: flex;
	flex-direction: row;
	padding: 0;
}
.form-body > * {
	flex-grow: 1;
}
.form-body > fieldset:not(:last-child) {
	margin-right: 2%;
}
.form-body input {
	width: 50%;
}
.form-body fieldset {
	margin-top: 2%;
}
fieldset {
	position: relative;
	border: 0 solid transparent;
}
legend {
}
label {
	display: block;
	margin: 5px;
}
form input {
	/*font-family:'Roboto', Arial, Sans-Serif;*/
	padding: 5px;
}
textarea {
	/*font-family:'Roboto', Arial, Sans-Serif;*/
	height: 200px;
	padding: 5px;
	width: 95%;
}
select {
	/*font-family:'Roboto', Arial, Sans-Serif;*/
	margin-left: 5px;
}
option {
}
label input[type="checkbox"],
label input[type="radio"] {
	display: inline !important;
}
input:required,
textarea:required,
select:required {
	/* border: 2px solid #376f19; */
}
.confirmation,
.tryagain {
	background-color: var(--backgroundcolor);
	font-size: 120%;
	font-weight: bold;
	max-width: 300px;
	padding: 3%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
}
.confirmation p,
.tryagain p {
	margin: 0;
}
.confirmation {
	border: 2px solid #376f19;
	color: #376f19;
}
.tryagain {
	border: 2px solid red;
	color: red;
}

.warning {
	background-color: var(--backgroundcolor);
	font-size: 120%;
	/* font-size: 3vw; */
	font-weight: bold;
	/* max-width: 300px; */
	max-width: 45vmax;
	max-height: 300px;
	padding: 1%;
	position: fixed;
	bottom: 1%;
	left: 1%;
	z-index: 999;
	border: 2px solid red;
	color: red;
}

.flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.flex-item {
	flex: 1;
	text-align: center;
	margin-top: 3%;
}

/* media */
figure {
	width: 100%;
	margin: 0 0 0 0;
	padding: 4% 4% 1% 4%;
}
figcaption {
	text-align: center;
	display: block;
	margin-bottom: 1%;
}
img {
	margin: 0;
	padding: 0;
}
iframe,
video,
object,
embed {
	width: 100%;
	height: 320px;
	display: block;
}
audio {
	width: 50%;
	height: 30px;
}
iframe {
	border: none;
}
code {
	white-space: pre-wrap;
}
/* start github inline code block */
code {
	padding: 0.2em 0.4em;
	margin: 0;
	font-size: 85%;
	background-color: var(--color-markdown-code-bg);
	-webkit-border-radius: 6px; /*Safari, Chrome*/
	-moz-border-radius: 6px; /*Firefox*/
	border-radius: 6px;
	font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono,
		Menlo, monospace;
}
/* -- reset for hljs with <pre><code> blocks */
pre code {
	padding: 0;
	font-size: 100%;
	background-color: inherit;
	-webkit-border-radius: 0; /*Safari, Chrome*/
	-moz-border-radius: 0; /*Firefox*/
	border-radius: 0;
	font-family: inherit;
}
/* end github inline code block */

/* typography */
p {
}
ol,
ul,
dl {
}
li {
}
/* reset ul point style inside ol */
ol > li > ul > li > ul > li > ul {
	list-style-type: square;
}
ul.initial > li > ul > li > ul {
	list-style-type: square;
}
ol > li > ul > li > ul {
	list-style-type: circle;
}
ul.initial > li > ul {
	list-style-type: circle;
}
ol > li > ul {
	list-style: initial;
}
ul.initial {
	list-style: initial;
}
/* end */
dt {
}
dd {
	font-style: italic;
}
dd ul {
	margin: 0;
}
address {
	font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	/*font-family: 'Coiny', cursive;*/
	font-weight: normal;
	margin: 0;
}
/* h1 {
	font-size: 300%;
	font-size: 2vmin;
	font-size: 3vmax;
	font-size: calc(12px + 1vw);
}
h2 {
	font-size: 250%;
	font-size: 1.5vmin;
	font-size: 2.5vmax;
}
h3 {
	font-size: 200%;
	font-size: 1vmin;
	font-size: 2vmax;
}
h4 {
	font-size: 150%;
	font-size: 0.75vmin;
	font-size: 1.5vmax;
}
h5 {
	font-size: 125%;
	font-size: 0.5vmin;
	font-size: 1.25vmax;
}
h6 {
	font-size: 100%;
	font-size: 0.25vmin;
	font-size: 1vmax;
} */
h1 {
	font-size: 2.5rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.75rem;
}
h4 {
	font-size: 1.5rem;
}
h5 {
	font-size: 1.25rem;
}
h6 {
	font-size: 1rem;
}
ul,
ol {
	overflow: hidden;
}
nav ul {
	overflow: initial;
}

/* table */
table {
	width: 100%;
	border-collapse: collapse;
}
caption {
}
thead {
}
tbody {
}
tfoot {
	display: none;
}
tr {
	border-bottom: 2px solid black;
}
th {
	padding: 1%;
	border-bottom: 2px solid black;
}
td {
	padding: 1%;
}

.updated {
	margin: 0;
}

/* embellishments */
.radius {
	-webkit-border-radius: 20px 20px 5px 5px; /*Safari, Chrome*/
	-moz-border-radius: 20px 20px 5px 5px; /*Firefox*/
	border-radius: 20px 20px 5px 5px;
}
.radius-scaled {
	-webkit-border-radius: 3vmin 3vmin 3vmin 3vmin; /*Safari, Chrome*/
	-moz-border-radius: 3vmin 3vmin 3vmin 3vmin; /*Firefox*/
	border-radius: 3vmin 3vmin 3vmin 3vmin;
}
.circle {
	-webkit-border-radius: 50%; /*Safari, Chrome*/
	-moz-border-radius: 50%; /*Firefox*/
	border-radius: 50%;
}
.boxshadow {
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color=#000000)"; /*IE 8*/
	-moz-box-shadow: 1px 1px 5px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /*FF 3.5+*/
	-webkit-box-shadow: 1px 1px 5px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
	box-shadow: 1px 1px 5px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color=#000000); /*IE 5.5-7*/
}
aside.boxshadow {
	-ms-filter: unset; /*IE 8*/
	-moz-box-shadow: unset; /*FF 3.5+*/
	-webkit-box-shadow: unset; /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
	box-shadow: unset; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
	filter: unset; /*IE 5.5-7*/
}
.tshadow {
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=1, Direction=135, Color=#000000)"; /*IE 8*/
	text-shadow: 2px 2px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=1, Direction=135, Color=#000000); /*IE 5.5-7*/
}
.shape {
	-webkit-clip-path: polygon(
		20% 0%,
		80% 0%,
		100% 20%,
		100% 80%,
		80% 100%,
		20% 100%,
		0% 80%,
		0% 20%
	);
}
.drop-shadow {
	filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3,Color='#f9e9e9')";
	filter: url(#drop-shadow);
	-webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.25));
	filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.25));
}
.tab {
	text-indent: 40px;
}

/* icons */
.material-icons {
	font-size: 24px;
	vertical-align: middle;
}
.material-icons {
	font-feature-settings: "liga";
} /* Support for IE. */
#share {
	margin: 50px 0 0 0;
}
#share a {
	display: inline;
	/*background-color:rgba(55,  111, 25, 0.85);*/
	padding: 2%;
}
#share a:hover {
	/*background-color:rgba(176, 104, 40, 0.85);*/
}

#print {
	position: relative;
	cursor: pointer;
}
#print i:hover {
	color: var(--navchildbackgroundhover, gray);
}
/* Rules for sizing the icon. */
.material-icons.md-18 {
	font-size: 18px;
}
.material-icons.md-24 {
	font-size: 24px;
}
.material-icons.md-36 {
	font-size: 36px;
}
.material-icons.md-40 {
	font-size: 40px;
}
.material-icons.md-48 {
	font-size: 48px;
}
.material-icons.md-64 {
	font-size: 64px;
}

#gallery {
	margin-top: 5%;
	margin-bottom: 5%;
}
.masonry {
	line-height: 1; /* 0 Prevents vertical gaps */
	-webkit-column-count: 1;
	-webkit-column-gap: 10px;
	-moz-column-count: 1;
	-moz-column-gap: 10px;
	-ms-column-count: 1;
	-ms-column-gap: 10px;
	column-count: 1;
	column-gap: 10px;
}
.masonry figure {
	-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
	page-break-inside: avoid; /* Firefox */
	break-inside: avoid; /* IE 10+ */
}
.masonry img {
	width: 100%;
	height: auto;
	border: 2px solid black;
}
.masonry figcaption {
	display: block;
	/*font-size:90%;*/
	margin-bottom: 5%;
}

/* ======================== Tablet ========================= */
@media all and (min-width: 1000px) {
	main {
		flex: 1;
		padding-top: calc(48px + 10px * 2 + 2%);
	}

	form {
		text-align: left;
		font-size: 150%;
		font-size: 1.5vmin;
	}
	.form-body fieldset {
		margin-top: 4%;
	}

	header {
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	aside {
		float: right;
		margin: 0 0 0 2%;
	}
	article,
	aside,
	footer {
		margin: 0% 6% 2% 6%;
		padding: 2%;
	}
	aside.boxshadow {
		-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color=#000000)"; /*IE 8*/
		-moz-box-shadow: 1px 1px 5px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /*FF 3.5+*/
		-webkit-box-shadow: 1px 1px 5px 1px
			var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
		box-shadow: 1px 1px 5px 1px var(--boxshadowcolor, rgba(0, 0, 0, 0.3)); /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
		filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color=#000000); /*IE 5.5-7*/
	}

	#articles li {
		display: inline-block;
	}

	#articles a {
		min-width: auto;
		max-width: 220px;
		padding: 10px;
	}

	legend {
		position: absolute;
		left: 0px;
		top: -30px;
		white-space: nowrap;
	}

	#articles a i {
		display: block;
	}

	.masonry {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}

	.contactform {
		margin-top: 2%;
	}

	video,
	iframe,
	object,
	embed {
		height: 540px;
	}
}
/* ======================== Desktop ======================== */
@media all and (min-width: 1500px) {
	main {
		flex: 1;
		padding-top: calc(64px + 10px * 2 + 2%);
	}

	#logo {
		font-size: 64px;
	}
	article,
	aside,
	footer {
		margin: 0% 8% 2% 8%;
		padding: 2%;
	}

	#articles a {
		width: 300px;
	}

	#about {
		margin-top: 0;
	}

	video,
	iframe,
	object,
	embed {
		max-width: 1280px;
		height: 720px;
	}

	/* navigation */
	#pages .parent li {
		font-size: 25px;
	}
	.children {
		width: 175%;
	}

	.masonry {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}
}
/* ========================= Print ========================= */
@media print {
	body {
		font-size: 10pt;
		background-image: none;
		background-size: 0 0;
	}
	h1 {
		font-size: 350%;
	}
	h2 {
		font-size: 250%;
	}
	h3 {
		font-size: 200%;
	}
	h4 {
		font-size: 150%;
	}
	h5 {
		font-size: 125%;
	}
	h6 {
		font-size: 100%;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 0 0;
		padding: 0;
	}

	iframe,
	video,
	audio,
	nav,
	.masonry,
	.warning,
	#contact,
	#pages,
	#print {
		display: none;
	}

	address p {
		display: inline;
	}

	.alignright {
		float: right;
		width: 50%;
		margin: 0 0 0 10pt;
	}
	.alignleft {
		float: left;
		width: 50%;
		margin: 0 10pt 0 0;
	}
	.clear {
		clear: both;
	}

	article,
	aside,
	header {
		margin: 0;
		padding: 0;
		border: 0;
	}

	.boxshadow {
		box-shadow: none;
	}
	.drop-shadow {
		filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3,Color='#ffffff')";
		filter: url(#drop-shadow);
		-webkit-filter: drop-shadow(3px 3px 3px rgba(255, 255, 255, 1));
		filter: drop-shadow(3px 3px 3px rgba(255, 255, 255, 1));
	}

	aside.fifty figure.thirty {
		width: 30%;
	}

	a:link,
	a:hover,
	a:active,
	a:visited {
		color: black;
	}

	.grecaptcha-badge {
		visibility: hidden;
	}
}
